import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { color, fontSize } from 'styled-system'

import { Container, Wrapper } from '../components/Container'
import { Text } from '../components/Typography'
import { Logo } from './Logo'

const Grid = styled.div`
  display: grid;
  padding: 20px 0;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media only screen and (max-width: 64em) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`

const Card = styled.div`
  justify-self: end;
  display: grid;
  grid-gap: 70px;
  grid-template-columns: auto auto;
  @media only screen and (max-width: 64em) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    justify-self: start;
  }
`
const StyledLink = styled.a`
  text-decoration: none;
  ${color}
`

const StyledInnerLink = styled(Link)`
  text-decoration: none;
  ${color}
  ${fontSize}
`

export const Footer = () => (
  <Wrapper bg="footer">
    <Container>
      <Grid>
        <Logo />
        <Card>
          <Text fontSize={{ _: 1 }} color="textSecondary">
            Contact:
            <StyledLink color="green" href="mailto:hello@safeternet.com">
              {' '}
              hello@safeternet.com
            </StyledLink>
          </Text>
          <StyledInnerLink fontSize={{ _: 1 }} color="green" to="/privacy-policy">
            <Text>Privacy policy</Text>
          </StyledInnerLink>
        </Card>
      </Grid>
    </Container>
  </Wrapper>
)
