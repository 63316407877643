import React from 'react'
import { width } from 'styled-system'
import styled from '@emotion/styled'
import LogoSvg from '../images/logo.svg'

const StyledLogo = styled.img`
  margin: 0;
  ${width}
`

export const Logo = () => (
  <StyledLogo width={{ _: '100px', lg: '150px' }} src={LogoSvg} alt="Safternet logo, wifi icon" />
)
