import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { margin, color, fontWeight, textAlign, fontSize, fontFamily } from 'styled-system'

export const HeaderStyles = () =>
  css`
    color: ${props => props.theme.colors.textSecondary};
    font-family: Baloo Thambi;
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
  `

export const H1 = styled.h1`
  font-size: 80px;
  ${HeaderStyles};
  ${margin};
  ${color};
  ${textAlign};
  ${fontWeight};
  ${fontSize};
  ${fontFamily}
`

export const H2 = styled.h2`
  font-size: 60px;
  ${HeaderStyles};
  ${margin};
  ${color};
  ${textAlign};
  ${fontWeight};
  ${fontSize};
  ${fontFamily}
`

export const H3 = styled.h3`
  color: ${props => props.theme.colors.textPrimary};
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  line-height: 1.3;
  margin: 0;
`

export const H4 = styled.h4`
  color: ${props => props.theme.colors.textPrimary};
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
`
