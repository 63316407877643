import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { grid, margin, color, fontSize, fontWeight, textAlign } from 'styled-system'

export const TextStyles = () =>
  css`
    color: ${props => props.theme.colors.textPrimary};
    font-family: Montserrat;
    line-height: 1.2;
    margin: 0;
  `

export const Text = styled.p`
  ${TextStyles}
  ${margin}
  ${grid}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
`
