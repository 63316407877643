import styled from '@emotion/styled'
import { padding, margin, color } from 'styled-system'

export const Container = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  ${padding}
  ${margin}
  ${color}
`

export const Wrapper = styled.section`
  ${color}
`
